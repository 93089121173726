﻿define(['jquery', 'pubsub', 'generalUtilities', 'ajaxUtility',
		'mixpanelControl', 'logglyController'],

	function ($, pubsub, generalUtilities, ajaxUtility) {
		'use strict';

		var module, elements, values;

		module = {};
		module.private = {};
		elements = {};
		values = {};

		elements.html = $('html');

		values.languageLinkSelector = '.js-select-language';
		values.languageLinkRedirectSelector = '.js-select-language-redirect';
		values.selectLanguageMoreLinkSelector = '.js-select-language-more-link';

		module.private.validateLanguageCode = function (l) {
			var languageRegex, result;

			languageRegex = /^[a-z]{2,3}(-[A-Z]{1}[a-z|A-Z]{1,3})?$/;
			result = languageRegex.test(l);

			if (!result) {
				pubsub.publish('loggly.error', { 'message': 'set language called with invalid langUage code: "' + l + '" on page: "' + window.location.pathname + '"'});
			}

			return result;
		};

		module.private.redirect = function (e) {
			var $target, href;

			$target = $(e.currentTarget);
			href = $(e.currentTarget).attr('href');

			if ($target.is(values.languageLinkRedirectSelector)) {
				window.location = href;
			} else {
				window.location.reload();
			}
		};

		module.private.populateLanguageHrefs = function () {
			var redirect, userDomain;

			userDomain = (RESAAS && RESAAS.User2 && RESAAS.User2.DomainUrl) ? RESAAS.User2.DomainUrl : false;
			redirect = generalUtilities.queryStringToObject(window.location.search)['redirect'] || userDomain || '/';

			$(values.languageLinkRedirectSelector).attr("href", redirect);
		};

		module.private.populateSelectLanguageMoreHref = function () {
			var href, newHref;

			href = $(values.selectLanguageMoreLinkSelector).attr('href');
			newHref = [href, '?redirect=', window.location.pathname].join('');

			$(values.selectLanguageMoreLinkSelector).attr('href', newHref);
		};

		module.private.setRemoteData = function () {
			return ajaxUtility.ajax({
				'type': 'PUT',
				'url': '/Services/Api/Preferences',
				'contentType': 'application/json',
				'dataType': 'json',
				'data': JSON.stringify({ 
					'Preferences': [
						{
							'Value': values.language,
							'PreferenceType': 2
						}
					]
				})
			}).then(
				function (d) {
					if (!d.Success) {
						pubsub.publish('loggly.error', { 'message': 'Failed to set language preference (webservice returned false): ' + values.language });
					}
				},
				function () {
					pubsub.publish('loggly.error', { 'message': 'Failed to set language preference (ajax error): ' + values.language });
				}
			);
		};

		module.private.setMixPanel = function () {
			var d = Q.defer();

			mixpanel.people.set({ $language: values.language }, function () {
				setTimeout(function () {
					d.resolve();
				}, 500)
			});

			return d.promise;
		};

		module.private.setLocalData = function () {
			var date;

			date = new Date();
			date.setTime(date.getTime() + (15 * 60000));

			document.cookie = "language=" + values.language + "; expires=" + date.toUTCString() + "; path=" + '/';

			if (window.Modernizr && window.Modernizr.localstorage) {
				localStorage.setItem('language-choice', values.language);
			}
		};

		module.private.handleLanguageClick = function (e) {
			var $this;

			e.preventDefault();

			$this = $(e.currentTarget);
			values.language = $this.attr('data-language');

			if (!module.private.validateLanguageCode(values.language)) {
				return false;
			}

			//if is beta show overlay
			//this should happen after the switch or show in the proposed, not current language

			module.private.setLocalData();

			if (RESAAS && RESAAS.User2 && RESAAS.User2.IsProfessional && RESAAS.User2.UserId) {
				module.private.setRemoteData()
					.then(module.private.setMixPanel)
					.then(module.private.redirect(e))
					.done();
			} else {
				module.private.redirect(e);
			}
		};

		//init
		module.private.populateLanguageHrefs();
		module.private.populateSelectLanguageMoreHref();

		elements.html.on('click', values.languageLinkSelector, module.private.handleLanguageClick);

		return module;

	}
);
